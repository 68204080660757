.App {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 0 2vw;
}

h1 {
    font-weight: 400;
    font-size: 80px;
}
